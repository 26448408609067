<template>
  <div v-if="getBadgeColor()" style="width: 25px; position: relative; display: inline-block;">
    <img 
      :src="require(`@/assets/badges/${getBadgeColor()}.svg`)" 
      @mouseover="showTooltip = true" 
      @mouseleave="showTooltip = false"
    />
    <div v-if="showTooltip" class="tooltip">
      {{ getTooltipText() }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserBadge',
  props: ['user'],
  data() {
    return {
      showTooltip: false
    };
  },
  methods: {
    getBadgeColor() {
      if (this.user.is_team_member) {
        return 'black';
      }
      if (this.user.is_ultime) {
        return 'blue';
      }
      if (this.user.club_subscription) {
        return 'purple';
      }
      if (this.user.is_customer) {
        return 'green';
      }
      return null;
    },
    getTooltipText() {
      const tooltips = {
        black: 'Staff',
        blue: 'Membre ultime',
        purple: 'Abonné club',
        green: 'Client'
      };
      return tooltips[this.getBadgeColor()] || '';
    }
  }
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}
</style>