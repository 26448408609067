<template>
    <v-app class="member">
        <v-app-bar app
                   ref="defaultToolbar"
                   id="default-toolbar"
                   :height="(user && !user.validated_at && $route.name !== 'member-cart' ? ($vuetify.breakpoint.mdAndDown ? 244 : 144) : 64)"
                   flat
                   v-if="PageStore.showToolbarApp"
                   style="padding-left: 16px; padding-right: 16px;"
                   :color="$vuetify.theme.dark ? 'secondary' : 'white'">
            
            <div style="width:100%;">
                <validate-email-banner class="mt-4" v-if="user && !user.validated_at && $route.name !== 'member-cart'" />

                <div class="d-flex justify-space-between" style="width:100%; align-items:center;">
                    <v-app-bar-nav-icon
                        v-if="$vuetify.breakpoint.mdAndDown && PageStore.showDrawer"
                        @click.stop="PageStore.drawer = !PageStore.drawer">
                    </v-app-bar-nav-icon>

                    <template v-if="$vuetify.breakpoint.mdAndUp && PageStore.title && PageStore.title.length > 0">
                        <span class="text-h2 default--text text--lighten-1">
                            {{ PageStore.title }}
                        </span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp && PageStore.breadcrumbs && PageStore.breadcrumbs.length > 0">
                        <v-breadcrumbs
                            class="pl-0"
                            :items="PageStore.breadcrumbs">
                            <template v-slot:divider>
                                <v-icon>$mg-arrow-right</v-icon>
                            </template>

                            <v-breadcrumbs-item slot="item"
                                                slot-scope="props"
                                                :to="props.item.to"
                                                exact
                                                :disabled="props.item.disabled">
                                <div class="text-subtitle-1 default--text d-flex" :class="props.item.cssClass">
                                    {{ props.item.text }} 
                                    <span v-if="props.item.subMenu" style="width: 20px; height: 20px;">
                                        <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                                        </svg>
                                    </span>

                                    <div v-if="props.item.subMenu" class="breadcrumb-sub-menu">
                                        <v-list dense>
                                            <v-list-item v-for="item in props.item.subMenu" :key="item.slug" :to="item.to" :disabled="item.disabled">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-icon>
                                                    <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </div>

                                </div>
                            </v-breadcrumbs-item>
                        </v-breadcrumbs>
                    </template>

                    <div v-else-if="$vuetify.breakpoint.smAndDown && $route.name === 'member-lesson-show' && PageStore.breadcrumbs[3]?.subMenu" class="default--text d-flex align-center" :class="PageStore.breadcrumbs[3].cssClass" @click="showMobileLessonsSubMenu = !showMobileLessonsSubMenu">
                        {{ PageStore.breadcrumbs[3].text }} 
                        <span v-if="PageStore.breadcrumbs[3].subMenu" style="width: 20px; height: 20px;">
                            <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                            </svg>
                        </span>
                    </div>

                    <template v-if="$vuetify.breakpoint.mdAndUp && PageStore.steppers && PageStore.steppers.length > 0">
                        <div v-if="$platformStore.notMyPianoPop">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                :width="133"
                                :height="48"
                                viewBox="0 0 138 48">
                                <path d="M73.0886 26.8268H67.1996C66.0953 26.8221 65.038 26.3893 64.2591 25.6234C63.4801 24.8575 63.0431 23.8208 63.0437 22.7403V13.5844C63.0437 11.532 62.0968 10.4646 60.4316 10.4646C58.7664 10.4646 57.7372 11.532 57.7372 13.5844V22.1582H53.2952V13.5844C53.2952 11.532 52.3689 10.4646 50.6831 10.4646C49.0364 10.4646 48.0072 11.532 48.0072 13.5844V22.1582H43.5447V6.82316H48.001V8.99633C49.0302 7.70733 50.3661 6.54119 52.6283 6.54119C54.664 6.54119 56.2284 7.40724 57.0291 8.95807C58.4062 7.38911 60.0529 6.54119 62.1915 6.54119C62.9481 6.53034 63.7005 6.65315 64.4125 6.90372C66.3659 7.60864 67.4774 9.39914 67.4774 12.1564V22.5409C67.4826 22.7209 67.558 22.8922 67.6882 23.0195C67.8183 23.1468 67.9933 23.2206 68.1773 23.2257H71.2607L71.4048 23.5882C71.8122 24.738 72.3787 25.8277 73.0886 26.8268Z"
                                    />
                                <path d="M72.9592 29.1027V37.6565C72.9592 39.7289 71.8888 40.7763 70.1824 40.7763C68.476 40.7763 67.488 39.7289 67.488 37.6565V29.1027H63.044V39.0442C63.044 42.5668 64.9974 44.7399 68.3711 44.7399C70.6126 44.7399 71.93 43.5516 72.9571 42.2646V44.4378H77.4012V29.1027H72.9592Z"
                                    />
                                <path d="M79.5622 29.1027V44.4378H84.0042V29.1027H79.5622Z" />
                                <path d="M96.1673 32.8247V29.1027H92.4787V25.1773H88.0141V29.1027H86.143V32.8268H88.0141V40.0915C88.0141 43.6544 89.8666 44.6997 92.5796 44.6997C94.0801 44.6997 95.1711 44.3573 96.0973 43.8135V40.3312C95.4229 40.6922 94.6657 40.8794 93.8969 40.875C92.9089 40.875 92.4787 40.3714 92.4787 39.3866V32.8247H96.1673Z"
                                    />
                                <path d="M104.368 28.9195C101.692 28.9195 100.008 29.4028 98.2793 30.148L99.3908 33.4894C100.832 32.9657 102.023 32.6435 103.713 32.6435C105.955 32.6435 107.087 33.6505 107.087 35.4632V35.7049C105.857 35.2848 104.563 35.0737 103.261 35.0805C99.539 35.0805 96.9249 36.6293 96.9249 39.9706V40.0311C96.9249 43.0703 99.3517 44.7399 102.314 44.7399C104.473 44.7399 105.976 43.9545 107.064 42.7883V44.4378H111.364V35.5417C111.36 31.3968 109.222 28.9195 104.368 28.9195ZM107.145 39.0422C107.145 40.6111 105.747 41.7189 103.668 41.7189C102.227 41.7189 101.221 41.0341 101.221 39.8679V39.8075C101.221 38.4379 102.394 37.6948 104.308 37.6948C105.284 37.6861 106.251 37.8782 107.147 38.2587L107.145 39.0422Z"
                                    />
                                <path d="M117.613 32.1822V29.1027H113.149V44.4378H117.613V38.7884C117.613 35.1248 119.423 33.3746 122.385 33.3746H122.611V28.8208C119.978 28.6999 118.519 30.0675 117.613 32.1822Z"
                                    />
                                <path d="M130.365 28.8208C125.8 28.8208 122.611 32.424 122.611 36.8106V36.8509C122.611 41.5598 126.088 44.7802 130.797 44.7802C133.636 44.7802 135.754 43.6926 137.215 41.9223L134.659 39.729C133.424 40.8367 132.356 41.2979 130.855 41.2979C128.86 41.2979 127.461 40.2708 127.029 38.299H137.938C137.98 37.9312 138.001 37.5613 138 37.1913V37.151C137.998 32.7845 135.612 28.8208 130.365 28.8208ZM126.971 35.5659C127.321 33.5941 128.513 32.3051 130.365 32.3051C132.218 32.3051 133.41 33.6344 133.677 35.5659H126.971Z"
                                    />
                                <path d="M52.2456 33.1873V37.0724H56.8522V39.9968C55.6995 40.8226 54.199 41.2254 52.5296 41.2254C48.8245 41.2254 46.2145 38.4883 46.2145 34.7441V34.6837C46.2145 31.2215 48.8678 28.3233 52.2003 28.3233C54.6271 28.3233 56.0453 29.0886 57.6509 30.3958L60.5573 26.988C58.3775 25.1753 56.1153 24.1683 52.3506 24.1683C46.0354 24.1683 41.4082 28.9376 41.4082 34.7341V34.7945C41.4082 40.8367 45.8914 45.2999 52.3917 45.2999C55.6643 45.3142 58.8304 44.1622 61.2983 42.0592V33.1873H52.2456Z"
                                    />
                                <path d="M79.4142 23.2197H83.7677V26.8248H78.8502C76.1887 26.8248 74.9023 25.6527 73.7043 22.5309L67.6753 6.82115H72.3025L75.7215 17.2721L79.2887 6.81713H84.0044L77.8622 22.2187C78.2409 22.9941 78.6464 23.2197 79.4142 23.2197Z"
                                    />
                                <path d="M18.3196 3.46191C5.16447 3.46191 0 8.96434 0 14.0176C0 30.4645 14.1761 45.3001 18.3196 45.3001C22.4631 45.3001 36.6392 30.4625 36.6392 14.0176C36.6433 8.96434 31.4809 3.46191 18.3196 3.46191ZM26.0838 32.3658C23.0477 36.9498 19.8407 39.84 18.3196 40.7624C16.7984 39.8379 13.5853 36.9498 10.5554 32.3658C8.26233 28.9056 4.41728 21.9208 4.41728 14.0176C4.41728 11.1093 8.53404 7.99759 17.4839 7.81028V12.0821C16.9045 12.2543 16.3969 12.604 16.036 13.0798C15.675 13.5555 15.4798 14.1321 15.479 14.7246V20.5754C15.4793 21.1683 15.6744 21.7453 16.0354 22.2215C16.3963 22.6976 16.9041 23.0476 17.4839 23.2199V28.0174H19.1573V23.2199C19.7371 23.0476 20.2449 22.6976 20.6059 22.2215C20.9668 21.7453 21.1619 21.1683 21.1622 20.5754V14.7246C21.1619 14.1317 20.9668 13.5547 20.6059 13.0785C20.2449 12.6024 19.7371 12.2524 19.1573 12.0801V7.81028C28.1113 7.99759 32.2239 11.1093 32.2239 14.0176C32.2219 21.9208 28.3768 28.9056 26.0838 32.3658Z"
                                    />
                            </svg>
                        </div>
                        <div v-else>
                            <svg :width="155" viewBox="0 0 1000 259" fill="FFF" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M401.099 129.121H365.5C351.71 129.121 340.393 118.714 340.393 105.882V53.801C340.393 42.128 334.663 36.0584 324.602 36.0584C314.528 36.0584 308.3 42.128 308.3 53.801V102.57H281.467V53.801C281.467 42.128 275.854 36.0584 265.675 36.0584C255.719 36.0584 249.492 42.128 249.492 53.801V102.57H222.527V15.3419H249.492V27.6966C255.719 20.39 263.792 13.7362 277.476 13.7362C289.775 13.7362 299.234 18.6627 304.074 27.481C312.408 18.5459 322.364 13.7362 335.291 13.7362C340.262 13.7362 344.75 14.424 348.714 15.7972C360.541 19.8106 367.239 30.0021 367.239 45.6705V104.753C367.239 106.8 369.241 108.631 371.479 108.631H390.031L390.907 110.7C393.524 117.109 396.624 123.517 401.099 129.121"
                                    />
                                <path
                                    d="M439.231 108.307C434.59 108.307 432.126 107.015 429.832 102.545L467.033 13.7362H438.47L416.868 73.9932L396.157 13.7362H368.132L404.638 104.338C411.9 122.328 419.699 129.098 435.809 129.098L461.816 129.121V108.188L439.231 108.307"
                                    />
                                <path
                                    d="M102.704 219.349C134.815 219.349 159.528 219.349 159.528 219.349C164.196 219.349 167.995 215.792 167.995 211.45V33.0471C167.995 28.6993 164.196 25.1517 159.528 25.1517H35.5398C30.8582 25.1517 27.0471 28.6993 27.0471 33.0471V211.45C27.0471 215.792 30.8582 219.349 35.5398 219.349C35.5398 219.349 63.1809 219.349 92.5097 219.349V133.304C90.9009 133.304 91.0326 133.304 91.0326 133.304C84.9664 133.304 80.0474 128.727 80.0474 123.08V35.353C80.0474 29.7056 84.9664 25.1284 91.0326 25.1284H104.036C110.089 25.1284 115.008 29.7056 115.008 35.353V123.08C115.008 128.727 110.089 133.304 104.036 133.304C104.036 133.304 105.394 133.304 102.704 133.304V219.349ZM159.528 244.505H35.5398C15.93 244.505 0 229.67 0 211.45V33.0471C0 14.8227 15.93 -0.000126967 35.5398 -0.000126967H159.528C179.111 -0.000126967 195.055 14.8227 195.055 33.0471V211.45C195.055 229.67 179.111 244.505 159.528 244.505Z"
                                    />
                                <path d="M340.659 142.857H365.385V230.769H340.659V142.857Z" />
                                <path
                                    d="M435.864 200.839V196.397C431.042 194.432 425.16 193.116 418.563 193.116C406.971 193.116 399.831 197.386 399.831 205.274V205.592C399.831 212.341 405.898 216.277 414.642 216.277C427.479 216.277 435.864 210.027 435.864 200.839V200.839ZM373.626 206.574V206.262C373.626 187.194 389.484 178.161 412.138 178.161C421.941 178.161 428.539 179.648 435.507 181.785V180.303C435.507 169.798 428.538 164.219 414.999 164.219C404.467 164.219 397.34 165.862 388.61 168.973L381.827 149.924C392.346 145.812 402.692 142.857 418.921 142.857C433.904 142.857 444.237 146.31 451.192 152.717C458.332 159.294 461.538 168.973 461.538 180.802V231.873H435.321V222.341C428.724 229.09 419.808 233.517 406.441 233.517C388.424 233.517 373.626 224.165 373.626 206.574"
                                    />
                                <path
                                    d="M475.275 141.77H501.648V154.393C507.704 146.918 515.68 140.11 528.873 140.11C548.985 140.11 560.44 152.573 560.44 173.16V230.769H534.065V181.126C534.065 169.176 528.358 163.03 518.115 163.03C507.885 163.03 501.648 169.176 501.648 181.126V230.769H475.275V141.77"
                                    />
                                <path
                                    d="M646.082 187.16V186.816C646.082 173.592 635.814 162.043 620.692 162.043C605.048 162.043 595.676 173.098 595.676 186.477V186.816C595.676 200.049 605.945 211.585 621.067 211.585C636.723 211.585 646.082 200.544 646.082 187.16ZM568.681 187.16V186.816C568.681 161.041 591.01 140.11 621.067 140.11C650.762 140.11 673.077 160.533 673.077 186.477V186.816C673.077 212.601 650.762 233.517 620.692 233.517C591.01 233.517 568.681 213.11 568.681 187.16Z"
                                    />
                                <path
                                    d="M305.99 154.322C305.99 164.446 297.891 172.213 277.264 172.213H250.967V136.102H276.675C296.467 136.102 305.99 142.681 305.99 154.322ZM250.235 195.334H276.284C315.853 195.497 332.418 181.326 332.418 153.822V153.478C332.418 129.174 315.187 112.637 279.262 112.637H222.527V195.324V230.769H250.235V195.334Z"
                                    />
                                <path
                                    d="M764.124 154.325C764.124 164.449 755.823 172.217 734.696 172.217H707.731V136.101H734.08C754.35 136.101 764.124 142.67 764.124 154.325ZM706.968 195.336H733.665C774.232 195.493 791.209 181.329 791.209 153.81V153.482C791.209 129.178 773.549 112.637 736.717 112.637H678.571V195.336V230.769H706.968V195.336"
                                    />
                                <path
                                    d="M866.572 187.146V186.814C866.572 173.601 856.57 162.039 841.87 162.039C826.614 162.039 817.506 173.095 817.506 186.49V186.814C817.506 200.041 827.483 211.598 842.208 211.598C857.465 211.598 866.572 200.547 866.572 187.146ZM791.209 187.146V186.814C791.209 161.041 812.94 140.11 842.208 140.11C871.126 140.11 892.857 160.533 892.857 186.49V186.814C892.857 212.597 871.126 233.517 841.87 233.517C812.939 233.517 791.209 213.103 791.209 187.146Z"
                                    />
                                <path
                                    d="M973.314 186.791V186.467C973.314 171.57 962.768 161.696 950.283 161.696C937.812 161.696 927.447 171.57 927.447 186.467V186.791C927.447 201.688 937.812 211.562 950.283 211.562C962.768 211.562 973.314 201.862 973.314 186.791ZM901.099 141.788H927.811V154.675C934.313 146.132 943.078 140.11 957.137 140.11C979.101 140.11 1000 156.512 1000 186.467V186.791C1000 216.745 979.453 233.137 957.137 233.137C942.909 233.137 934.131 226.952 927.811 219.759V258.242H901.099V141.788Z"
                                    />
                            </svg>
                        </div>

                        <v-container class="layout-padding-x">
                            <v-stepper v-model="PageStore.currentStepKey" class="transparent" flat>
                                <v-stepper-header>
                                    <template v-for="(stepper, index) in PageStore.steppers">
                                        <v-stepper-step
                                            :key="`${index}-step`"
                                            :complete="PageStore.currentStepKey > index"
                                            :step="index + 1"
                                            :id="PageStore.currentStepKey === index ? 'currentStep' : null"
                                        >
                                            <span :class="{'font-weight-bold': PageStore.currentStepKey === index}">{{
                                                    stepper.text
                                                }}</span>
                                        </v-stepper-step>
                                        <v-divider style="border-width: 1px;" class="border rounded"
                                                v-if="index + 1 !== PageStore.steppers.length" :key="index"></v-divider>
                                    </template>
                                </v-stepper-header>
                            </v-stepper>
                        </v-container>

                    </template>

                    <div v-if="$route.params?.courseTab !== 'repertory' && $route.name !== 'member-songs-list' && $route.name !== 'member-lesson-show' && $route.name !== 'member-course-module-show' && $route.name !== 'member-course-show' && $route.name !== 'member-course-detail' && $route.name !== 'repertory-lesson' && $route.name !== 'member-cart'" class="ml-4" :style="`${$vuetify.breakpoint.mdAndUp ? `width: 30%;` : ''}`">
                        <global-search />
                    </div>

                    <v-spacer></v-spacer>

                    <template v-if="PageStore.showProfileBtn">
                        <v-menu offset-y left min-width="300" :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                                <div v-if="user && user.username"
                                    v-on="on" class="clickable pl-4 font-weight-bold d-flex align-center" style="height: 50px">
                                    <div class="pr-1 d-flex align-center" style="min-height: 24px;"
                                        v-if="$vuetify.breakpoint.mdAndUp">
                                        <div>{{ user.username }}</div>
                                        <user-badge :user="user"></user-badge>
                                    </div>
                                </div>

                                <v-avatar size="32"
                                        class="clickable ml-4"
                                        v-on="on">
                                    <img v-if="user.avatar" v-media-src="user.avatar.data">
                                    <v-img v-else :src="require('@/assets/mg-avatar-logo.svg')"></v-img>
                                </v-avatar>

                                <user-notification-container
                                    class="pr-4 pl-4" style="border-right: 1px solid #E8E8E8;"
                                    v-show="user && $route.name !== 'member-cart' && $vuetify.breakpoint.mdAndUp"/>
                                <template v-if="user.currentQuest && !isFocusDisplay">
                                    <div v-show="!$route.name.includes('member-cart') && $vuetify.breakpoint.mdAndUp" class="pr-4 pl-4" style="border-right: 1px solid #E8E8E8; width: 10%; height: 65%; display: flex; align-items: center; cursor: pointer;"
                                        @mouseover="showCompletion = true;"  
                                        @mouseleave="showCompletion = false;"
                                        @click="showCompletionSteps = true;">
                                        <v-progress-linear :value=user.currentQuest.data.completion_rate
                                                            height="25"
                                                            rounded
                                                            :color="user.currentQuest.data.completion_rate === 100 ? 'success' : 'primary'">
                                                                <span class="white--text">
                                                                    {{ user.currentQuest.data.completed_steps }}/{{ user.currentQuest.data.total_steps }}
                                                                </span>
                                        </v-progress-linear>
                                    </div>
                                </template>
                            </template>

                            <v-list class="">
                                <v-list-item class="px-4">
                                    <v-list-item-content>
                                        <v-list-item-title>Statut :</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <user-badge :user="user"></user-badge>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item class="px-4" :to="{name : 'member-notifications'}">
                                    <v-list-item-content>
                                        <v-list-item-title>Notifications</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-badge
                                            id="notification-badge"
                                            :content="notificationsLength"
                                            class="mr-2"
                                        >
                                            <v-icon>mdi-bell</v-icon>
                                        </v-badge>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item class="px-4" :to="{name : 'member-user-detail', params: {userUuid: user.id}}">
                                    <v-list-item-content>
                                        <v-list-item-title>Profil</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon>mdi-account</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item class="px-4" :to="{name : 'member-profile'}">
                                    <v-list-item-content>
                                        <v-list-item-title>Mon compte</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon>mdi-cog</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item
                                    class="px-4"
                                    v-if="true"
                                    :href="this.theOtherPlatform.url" target="_blank">
                                    <v-list-item-content>
                                        <v-list-item-title>Espace {{ this.theOtherPlatform.name }}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon>mdi-call-made</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider v-if="$authLogic.hasRole(['admin', 'teacher'])"></v-divider>
                                
                                <v-list-item
                                    class="px-4"
                                    v-if="$authLogic.hasRole(['admin', 'teacher'])"
                                    :href="adminUrl">
                                    <v-list-item-content>
                                        <v-list-item-title>Espace admin</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon>mdi-call-made</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item class="px-4" link @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                                    <v-list-item-content>
                                        Thème sombre
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-switch
                                            v-model="$vuetify.theme.dark"
                                            hide-details
                                            color="white"
                                            disabled
                                            @change="switchTheme"
                                            class="mt-0 pt-0"
                                        ></v-switch>
                                    </v-list-item-icon>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item class="px-4" @click="logout">
                                    <v-list-item-content>
                                        <v-list-item-title>Déconnexion</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon>mdi-power</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </div>
            </div>

        </v-app-bar>

        <v-navigation-drawer
            v-if="PageStore.showDrawer"
            color="secondary"
            v-model="PageStore.drawer"
            :width="isFocusDisplay ? 75 : 248"
            :mobile-breakpoint="mobileBreakPoint"
            app>
            <v-list class="pl-6" height="64">
                <v-list-item class="pa-0" style="height: 64px">
                    <v-list-item-title class="title white--text drawer-toolbar-title" style="height: 48px">
                        <component :is="drawerLogoComponent"></component>
                    </v-list-item-title>
                </v-list-item>
            </v-list>

            <v-list class="pt-10 mt-8 title transparent"
                    id="dirty-drawer-color">
                <div v-for="(item) in items.filter(element => !element.hide)" :key="item.title">
                    <v-tooltip
                        bottom max-width="248"
                        color="primary"
                        :disabled="(isFocusDisplay ? false : !item.disabled)">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-list-item
                                    v-if="item.url"
                                    color="white"
                                    class="pl-6 pr-4 py-0"
                                    :disabled="item.disabled"
                                    :class="item.class">
                                    <v-list-item-icon class="mr-4">
                                        <v-icon v-if="item.icon" size="24" color="accent" :disabled="item.disabled">
                                            {{ item.icon }}
                                        </v-icon>
                                        <mg-all-courses-icon v-else-if="item.svg === 'mg-all-courses'" height="24"
                                                             width="24"/>
                                        <mg-support-icon v-else-if="item.svg === 'mg-support'" height="24" width="24"/>
                                    </v-list-item-icon>
                                    <v-list-item-title class="text-h4 font-weight-bold text-wrap">
                                        <a style="color: white; text-decoration: none;" target="_blank"
                                           :href="item.url">{{ item.title }}</a>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-else
                                    color="white"
                                    class="pl-6 pr-4 py-0"
                                    :to="item.to"
                                    :class="item.class"
                                    :disabled="item.disabled">
                                    <v-list-item-icon class="mr-4">
                                        <v-icon v-if="item.icon" size="24" color="accent" :disabled="item.disabled">
                                            {{ item.icon }}
                                        </v-icon>
                                        <mg-all-courses-icon v-else-if="item.svg === 'mg-all-courses'" height="24"
                                                             width="24"/>
                                        <mg-support-icon v-else-if="item.svg === 'mg-support'" height="24" width="24"/>
                                    </v-list-item-icon>
                                    <div class="selected-caret"></div>
                                    <v-list-item-title v-if="!isFocusDisplay" class="text-h4 font-weight-bold text-wrap">
                                        {{ item.title }} <v-badge v-if="showLiveBadge(item)" dot color="success" class="mb-1"></v-badge>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>

                        </template>

                        <div v-if="isFocusDisplay" class="text-center text-body-2"
                             v-html="item.tooltipMessage && item.disabled ? item.tooltipMessage : item.title">
                        </div>
                        <div v-else class="text-center text-body-2"
                             v-html="item.tooltipMessage ? item.tooltipMessage : 'L\'accès est désactivé'">
                        </div>
                    </v-tooltip>
                </div>
            </v-list>
        </v-navigation-drawer>

        <v-main>

            <v-card tile flat v-if="$route.name === 'member-course-list'  && $route.params.courseTab == 'repertory'"
                    style="position: sticky; top:64px;z-index:1">
                <v-divider style="border-width: thin 0 2px 0;"></v-divider>
                <songs-list-filter class="layout-padding-x">
                </songs-list-filter>
            </v-card>

            <v-card tile flat v-if="$route.name === 'member-songs-list'"
                    style="position: sticky; top:64px;z-index:1">
                <v-divider style="border-width: thin 0 2px 0;"></v-divider>
                <songs-list-filter class="layout-padding-x">
                </songs-list-filter>
            </v-card>

            <v-card tile flat v-if="$route.name === 'songs'"
                    style="position: sticky; top:64px;z-index:1">
                <v-divider style="border-width: thin 0 2px 0;"></v-divider>
                <songs-list-filter class="layout-padding-x mb-6 mb-md-3" />
            </v-card>

            <v-card tile flat v-if="$route.name === 'map'"
                    style="position: sticky; top:64px;z-index:1">
                <v-divider style="border-width: thin 0 2px 0;"></v-divider>
                <!-- <map-filter class="layout-padding-x" /> -->
            </v-card>

            <v-card tile flat v-if="$route.name === 'member-video'"
                    style="position: sticky; top:64px;z-index:1">
                <v-divider style="border-width: thin 0 2px 0;"></v-divider>
                <video-list-filters class="layout-padding-x">
                </video-list-filters>
            </v-card>

            <v-container
                :class="{
                        'layout-padding-x': !['support', 'support-category', 'support-article'].includes($route.name),
                        'py-8' : $vuetify.breakpoint.mdAndUp && !['support', 'support-category', 'support-article'].includes($route.name),
                        'container--fluid pb-5': $route.name !== 'member-cart' || $route.name === 'member-cart-vip',
                    }"
                :style="$route.name === 'member-cart' || $route.name === 'member-cart-vip'|| $route.name === 'map' ? 'min-height: 100%; height: 100%;' : ''"
            >
                <router-view/>
                <v-dialog v-if="user.currentQuest && user.currentQuest.data.quest" v-model="showCompletionSteps"
                      max-width="500px">
                        <v-card flat
                                :class="{'pt-9 px-10 pb-6' : $vuetify.breakpoint.smAndUp || $vuetify.breakpoint.height >= 470, 'py-4 px-2' : !$vuetify.breakpoint.smAndUp,  }">
                            <v-btn icon
                                @click="showCompletionSteps = false" small
                                style="position: absolute;right: 10px;top: 10px;">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <template>
                                <h2 class="primary--text text-h2 font-weight-bold">
                                    Etapes pour {{ user.currentQuest.data.quest.data.title }}
                                </h2>
                                <div class="pt-3 pb-4 my-7">
                                    <p>
                                        {{ user.currentQuest.data.quest.data.incentive }}
                                    </p>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in JSON.parse(user.currentQuest.data.steps)"
                                            :key="index"
                                        >
                                            <template v-if="item">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-check</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    {{ index }}
                                                </v-list-item-title>
                                            </template>
                                            <template v-else>
                                                <v-list-item-icon class="default--text text--lighten-2 text-body-1">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title class="default--text text--lighten-2 text-body-1">
                                                    {{ index }}
                                                </v-list-item-title>
                                            </template>
                                        </v-list-item>
                                    </v-list>
                                </div>
                                <v-card-actions v-if="user.currentQuest.data.quest.data.cta_link && user.currentQuest.data.quest.data.cta_text" class="pa-0" style="items-align : center;">
                                    <v-btn depressed rounded small color="primary"
                                            @click="showCompletionSteps = false"
                                            :to="user.currentQuest.data.quest.data.cta_link">
                                        {{ user.currentQuest.data.quest.data.cta_text }}
                                    </v-btn>
                                </v-card-actions>
                            </template>
                        </v-card>
                </v-dialog>
                <v-dialog v-if="user.currentQuest && user.currentQuest.data.quest" v-model="questCompleteDialog"
                      max-width="500px">
                        <v-card flat
                                :class="{'pt-9 px-10 pb-6' : $vuetify.breakpoint.smAndUp || $vuetify.breakpoint.height >= 470, 'py-4 px-2' : !$vuetify.breakpoint.smAndUp,  }">
                            <v-btn icon
                                @click="questCompleteDialog = false" small
                                style="position: absolute;right: 10px;top: 10px;">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <template>
                                <h2 class="primary--text text-h2 font-weight-bold">
                                    Bravo ! 
                                </h2>
                                <div class="pt-3 pb-4 my-7">
                                    <p>
                                        {{ user.currentQuest.data.quest.data.congratulations_phrase }}
                                    </p>
                                    <p>
                                        Tu as terminé la quête {{ user.currentQuest.data.quest.data.title }}
                                    </p>
                                </div>
                                <v-card-actions class="pa-0" style="items-align : center;">
                                    <v-btn depressed rounded small color="primary"
                                            @click="questCompleteDialog = false"
                                            :to="{name : 'member-quest-list'}">
                                        Voir les quêtes suivantes
                                    </v-btn>
                                </v-card-actions>
                            </template>
                        </v-card>
                </v-dialog>



                <v-dialog v-model="openInstrumentsDialog" persistent max-width="800">
                    <template>
                        <v-card>
                            <v-card-title class="font-weight-bold">Avant de continuer, choisi ton instrument préféré !</v-card-title>
                            <v-row v-if="instrumentLoading" no-gutters class="pb-10" style="justify-content: center;">
                                <div>
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                    ></v-progress-circular>                                
                                </div>
                            </v-row>
                            <div v-else no-gutter class="mt-6">
                                <v-row no-gutters class="pb-6">
                                    <v-col cols="12" md="6" v-for="instrument in instrumentsAvailable" :key="instrument.id" class="text-center">
                                        <div class="instrument-container" @click="setIntrument(instrument)">
                                            <div class="instrument-box">
                                                <div class="instrument-name">{{ instrument.name }}</div>
                                                <v-img 
                                                    contain  
                                                    width="300" 
                                                    height="200" 
                                                    class="instrument-image"
                                                    :src="require(`@/assets/guitars/${instrument.name === 'Guitare Acoustique' ? 'classic.png' : 'electric.png'}`)"
                                                />
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="text-center mt-4">
                                        <v-btn rounded style="background: orange; color: white;" :loading="instrumentLoading" @click="setIntrument('all')" class="pa-2 ma-2 text-center text-h2">
                                            Les deux !
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-card-text class="text-center">
                                <p class="font-weight-bold">Tu pourras toujours modifier ton choix depuis tes paramètres plus tard.</p>
                                <p>Ta réponse nous permet d'adapter nos conseils pour mieux te correspondre.</p>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog v-if="PageStore.breadcrumbs[3]?.subMenu" v-model="showMobileLessonsSubMenu" max-width="800">

                    <template>
                        <v-card>
                            <v-card-title class="font-weight-bold">Navigue sur une autre leçon</v-card-title>
                            <div v-if="PageStore.breadcrumbs[3].subMenu">
                                <v-list dense>
                                    <v-list-item v-for="item in PageStore.breadcrumbs[3].subMenu" :key="item.slug" :to="item.to" :disabled="item.disabled">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </div>
                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                color="primary"
                                @click="showMobileLessonsSubMenu = false"
                                >Fermer</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-container>

            <v-snackbar
                v-model="snackbar.open"
                timeout="5000"
                :color="snackbar.color"
            >
                <p class="mb-0 text-subtitle-1">{{ snackbar.text }}</p>
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar.open = false"
                    >
                        Fermer
                    </v-btn>
                </template>
            </v-snackbar>

            <AppFooter v-if="$route.name !== 'member-lesson-show' && $route.name !== 'member-course-module-show' && $route.name !== 'member-course-show' && $route.name !== 'member-course-detail' && $route.name !== 'repertory-lesson' && $route.name !== 'member-cart'" />
        </v-main>
    </v-app>
</template>

<style lang="scss">
.theme--light {
    #default-toolbar {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    }
}

.theme--dark {
    #default-toolbar {
        /*border-bottom: 2px solid #29333d !important;*/
    }
}

.support-absolute-bottom {
    @media (min-height: 550px) {
        position: absolute;
        bottom: 24px;
        width: 100%;
    }
}

.layout-padding-x {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;

    @media #{map-get($display-breakpoints, 'md-and-up')}{
        padding-left: 32px;
        padding-right: 32px;
    }
}

#dirty-drawer-color {

    .v-list-item--active {
        background: #001427;

        &::before {
            opacity: 0;
        }

        .selected-caret {
            position: absolute;
            width: 8px;
            height: 56px;
            left: 0px;

            background: #2797FF;
            box-shadow: inset 0px 3px 20px rgba(0, 33, 69, 0.15), inset 0px 4px 4px rgba(0, 33, 69, 0.2);
            border-radius: 0px 8px 8px 0px;
        }
    }

    .v-list-item {
        &:not(.v-list-item--active):not(.v-btn__content):not(.v-list-item--disabled) {
            color: #ffffff !important;
        }

        &.v-list-item--disabled {
            color: rgba(255, 255, 255, 0.40) !important;

            .v-icon {
                color: rgba(255, 255, 255, 0.40) !important;
            }
        }
    }
}

#currentStep > span {
    background: var(--v-primary-base) !important;
    color: white !important;
}

#logosContainer {
    position: absolute;
    bottom: 90px;

    .v-btn--icon {
        height: 40px;
    }
}

.v-navigation-drawer--open {
    z-index: 200;
}

@media (min-height: 768px) {
    #logosContainer {
        flex-direction: column;
    }
}

.breadcrumb-sub-menu {
    position: absolute;
    display: none;
    max-height: 500px;
    overflow: scroll;
  /* autres styles pour le sous-menu */
}

.breadcrumb-sub-menu::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background: white;
}

.breadcrumb-sub-menu::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #2096F3;
}


.text-subtitle-1:hover .breadcrumb-sub-menu {
  display: block;
}

.instrument-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.instrument-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #0175E2;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  width: 300px; /* Largeur du cadre */
}

.instrument-name {
  font-size: 20px;
  font-weight: bold;
  background: #0175E2;
  color: white;
  width: 100%;
  padding: 10px;
}

.instrument-image {
  transition: opacity 0.3s ease;
}

.instrument-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.instrument-box:hover .instrument-image {
  opacity: 0.8;
}
</style>

<script>
    import AuthLogic from 'mg-js-core/lib/logics/AuthLogic'
    import PageStore from 'mg-js-core/lib/stores/PageStore'
    import {EventBus} from 'mg-js-core/lib/services/Request'
    import Helpers from "mg-js-core/lib/services/Helpers";
    import MgLogoIcon from "../../components/icon/LogoIcon";
    import MemberProductListFilters from "./cart/ProductListFilters";
    import MemberCourseListFilters from "@/components/course/CourseListFilters";
    import VideoListFilters from "@/components/video/VideoListFilters";
    import UserNotificationContainer from "@/components/user/UserNotificationContainer";
    import UserLogic from "mg-js-core/lib/logics/UserLogic";
    import UserQuestLogic from "mg-js-core/lib/logics/UserQuestLogic";
    import MgAllCoursesIcon from "@/components/icon/MgAllCourses";
    import MgSupportIcon from "@/components/icon/MgSupport";
    import MppLogoIcon from "@/components/icon/MppLogoIcon";
    import MgSocialLinks from "@/components/MgSocialLinks";
    import MppSocialLinks from "@/components/MppSocialLinks";
    import SongsListFilter from '@/components/songs/SongsListFilter.vue';
    import MapFilter from '@/components/map/MapFilter.vue';
    import Confetti from '@/assets/js/confetti.js';
    import AppFooter from '@/components/footer/AppFooter.vue';
    import GlobalSearch from '@/components/GlobalSearch.vue';
    import UserBadge from '@/components/user/UserBadge.vue';
    import ValidateEmailBanner from '@/components/ValidateEmailBanner.vue';
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic';
    import WebNotificationLogic from "mg-js-core/lib/logics/WebNotificationLogic";

    export default {
        name: 'member-layout',

        components: {
            MgSupportIcon,
            MgAllCoursesIcon,
            MemberCourseListFilters,
            MemberProductListFilters,
            VideoListFilters,
            MgLogoIcon,
            MppLogoIcon,
            UserNotificationContainer,
            MgSocialLinks,
            MppSocialLinks,
            SongsListFilter,
            MapFilter,
            AppFooter,
            GlobalSearch,
            ValidateEmailBanner,
            UserBadge
        },

        metaInfo() {
            // if no subcomponents specify a metaInfo.title, this title will be used
            // title: 'Cours de guitare en ligne pour apprendre avec plaisir',
            // all titles will be injected into this template
            return {
                titleTemplate: this.$trans.get('brand.name') + ' | %s'
            }
        },

        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.getSettings();

            this.getAllInstruments();

            if (this.user.number_connections >= 4 && this.$platformStore.notMyPianoPop && this.user.instruments.data.length === 0) {
                this.openInstrumentsDialog = true;
            }

            EventBus.$on('toastr', (value) => {
                this.snackbar.open = true;
                this.snackbar.text = value.text;
                this.snackbar.color = value.color;
            });
        },

        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        data: () => ({
            user: AuthLogic.me(),
            pagination: Helpers.initPagination(30, 1, 'created_at', true),
            adminUrl: process.env.VUE_APP_ADMIN_HOST,
            showCompletion: false,
            showCompletionSteps: false,
            questCompleteDialog: false,

            settings: UserLogic.getEmptySettingsObject(),

            instrumentsAvailable: null,

            openInstrumentsDialog: false,

            instrumentLoading: false,

            PageStore,

            window: {
                width: 0,
                height: 0
            },

            mobileBreakPoint: 1264,

            selected: [0, 1, 2, 3, 4],

            notifications: [
                {
                    action: '15 min',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: "Cours de Blues Débutant Gratuit"
                },
                {
                    action: '2 hr',
                    title: 'Vous avez obtenu un nouveau badge',
                    subtitle: "Solo dans la Peau"
                },
                {
                    action: '6 hr',
                    title: 'Vous avez obtenu un nouveau badge',
                    subtitle: 'Le Rythme dans la Peau'
                },
                {
                    action: '12 hr',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: 'Pack Arpèges Niveau 1'
                },
                {
                    action: '18hr',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: 'SOLO - Niveau Intermédiaire 2'
                }
            ],

            snackbar: {
                open: false,
                text: null,
                color: null
            },

            theOtherPlatform: {
                name: process.env.VUE_APP_PLATFORM_CURRENT === process.env.VUE_APP_PLATFORM_MG ? 'MyPianoPop' : 'MyGuitare',
                url: process.env.VUE_APP_THE_OTHER_PLATFORM_URL,
            },
            showMobileLessonsSubMenu: false,
            notificationsLength: null,
        }),

        watch: {
            '$vuetify.theme.dark'(value) {
                UserLogic
                    .updateMeSettings({
                        ...this.settings,
                        theme_color: value ? 1 : 0
                    })
                    .then((data) => {
                        console.log(data);
                    })
            }, 

            rate: {
                immediate: true, 
                handler (value) {
                    if (value === 100) {
                        this.questCompleteDialog = true;
                        this.burstConfetti();
                    }
                }
            },

            questCompleteDialog:function(newValue) {
                if(!newValue){
                    this.next();
                }
            },

            '$route': {
                handler() {
                    this.showMobileLessonsSubMenu = false;
                },
                deep: true,
            }

        },

        computed: {
            rate() {
                return  this.user.currentQuest ? this.user.currentQuest.data.completion_rate : 0;
            },

            drawerLogoComponent() {
                return this.$platformStore.currentDrawerLogoComponent
            },

            socialLinkComponent() {
                return this.$platformStore.currentSocialLinkComponent
            },

            drawerWidth() {
                const minWidth = 250;
                const maxWidth = 310;
                const drawerWidth = this.window.width * 0.15

                if (this.window.width < this.mobileBreakPoint) {
                    return maxWidth
                } else if (drawerWidth < minWidth) {
                    return minWidth;
                } else if (drawerWidth > maxWidth) {
                    return maxWidth
                } else {
                    return drawerWidth
                }
            },

            fontColor() {
                return this.$vuetify.theme.dark ? 'white' : 'white'
            },

            items() {
                let items = [
                    {
                        // icon: 'mdi-view-dashboard',
                        icon: '$mg-home',
                        title: 'Accueil',
                        to: {
                            name: 'member-dashboard'
                        },
                    },
                    {
                        icon: '$mg-my-learning',
                        title: 'Mes cours',
                        to: {
                            name: 'member-course-list',
                            params: {
                                courseTab: 'all'
                            }
                        },
                    },
                    {
                        // icon: '$mg-all-courses',
                        svg: 'mg-all-courses',
                        title: 'Tous les cours',
                        to: {
                            name: 'member-product-list'
                        }
                    },
                    {
                        // icon: '$mg-all-courses',
                        icon: 'mdi-star-outline',
                        title: `${(this.user.club_tier ? `Club ${this.$trans.get('brand.name')}` : 'Accès club')} 🔥`,
                        to: {
                            name: 'member-club-offers',
                        }
                    },
                    // {
                    //     // icon: '$mg-all-courses',
                    //     icon: 'mdi-radio-tower',
                    //     title: 'Live Hebdo',
                    //     to: {
                    //         name: 'member-live-hebdo',
                    //     },
                    //     hide: !this.$platformStore.notMyPianoPop,
                    // },
                    {
                        // icon: '$mg-all-courses',
                        icon: 'mdi-music-note-outline',
                        title: 'MyRépertoire',
                        to: {
                            name: 'member-course-list',
                            params: {
                                courseTab: 'repertory',
                            }
                        }
                    },
                    {
                        icon: '$mg-instructor',
                        title: 'Mes feedbacks VIP',
                        disabled: !this.user.user_courses_count && (!this.user.user_courses_vip_count || !this.user.has_global_vip),
                        tooltipMessage: (() => {
                            return 'Cette fonctionnalité est réservée aux élèves qui ont acheté un cours avec l\'option VIP'
                        })(),
                        to: {
                            name: 'member-vip-summary'
                        }
                    },
                    // {
                    //     // icon: 'mdi-view-dashboard',
                    //     icon: 'mdi-account-box',
                    //     title: 'Communauté',
                    //     to: {
                    //         name: 'member-user-detail',
                    //         params: {
                    //             userUuid: this.user.id,
                    //         },
                    //     },
                    // },
                    // {
                    //     icon: 'mdi-youtube',
                    //     title: `Vidéos des  ${this.$trans.choice('brand.customer_name', 2)}`,
                    //     // title: "Vidéos des " + $trans.choice('brand.customer_name', 2),
                    //     to: {
                    //         name: 'member-video',
                    //     },
                    // },
                    // {
                    //     icon: '$mg-chat',
                    //     tooltipMessage: `Cette fonctionnalité est réservée aux élèves qui ont acheté un cours ou ceux qui font partie du Club ${this.$trans.get('brand.name')}`,
                    //     disabled: !this.user.has_forum_feature,
                    //     title: 'Forum',
                    //     to: {
                    //         name: 'forum'
                    //     }
                    // },
                    // {
                    //     icon: 'mdi-chat-processing-outline',
                    //     title: 'Chat',
                    //     to: {
                    //         name: 'member-community-chat'
                    //     }
                    // },
                    // {
                    //     icon: 'mdi-lightbulb-on-outline',
                    //     title: 'Aide',
                    //     disabled: false,
                    //     to: {
                    //         name: 'support'
                    //     },
                    // },
                    // {
                    //     icon: 'mdi-map-marker-outline',
                    //     tooltipMessage: `Cette fonctionnalité est réservée aux élèves qui ont acheté un cours ou ceux qui font partie du Club ${this.$trans.get('brand.name')}`,
                    //     disabled: !this.user.has_map_feature,
                    //     title: 'Carte',
                    //     to: {
                    //         name: 'map'
                    //     }
                    // },
                    // {
                    //     icon: this.$platformStore.notMyPianoPop ? 'mdi-piano' : 'mdi-guitar-electric',
                    //     tooltipMessage: '',
                    //     disabled: false,
                    //     title: this.theOtherPlatform.name,
                    //     to: {
                    //         name: "the-other-platform",
                    //     }
                    // }
                ];

                return items
            },
            isFocusDisplay() {
                return this.$vuetify.breakpoint.mdAndUp && (this.$route.name === 'member-lesson-show' || this.$route.name === 'repertory-lesson');
            }
        },

        mounted() {
            AuthLogic.loadMe().then(({data}) => {
                this.user = Object.assign(this.user, data)
            });

            EventBus.$on('refresh-user', () => {
                AuthLogic.loadMe().then(({data}) => {
                    this.user = Object.assign(this.user, data)
                });
            });

            this.prepareConfetti();
            this.getWebNotifications();
        },

        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },

            logout() {
                AuthLogic
                    .logout()
                    .then(() => this.$router.push({name: 'login'}))
            },

            switchTheme(value) {
                this.$vuetify.theme.dark = value;
            },

            getSettings() {
                UserLogic
                    .getMeSettings()
                    .then((data) => {
                        if (data.data) {
                            this.settings = data.data
                            this.$vuetify.theme.dark = data.data && data.data.theme_color === 1;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            },

            burstConfetti() {
                EventBus.$emit('confetti');
            },

            prepareConfetti() {
                let tag = document.createElement('script');
                tag.setAttribute('type','application/javascript');
                let confetti = new Confetti('confetti');
                confetti.setCount(200);
                confetti.setSize(1);
                confetti.setPower(50);
                tag.nodeValue = confetti;
                document.head.appendChild(tag);
            },

            next() {
                UserQuestLogic
                    .next()
                    .then(() => {
                        EventBus.$emit('refresh-user');
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            showLiveBadge(item) {
                if (item.to.name === 'member-live-hebdo') {

                    let now = new Date();
                    let gmtPlusTwo = new Date(now.toLocaleString('en-FR', {timeZone: 'Europe/Paris'}));

                    let day = gmtPlusTwo.getDay();
                    let hour = gmtPlusTwo.getHours();
                    let minute = gmtPlusTwo.getMinutes();

                    if(day === 4 && ((hour === 12 && minute >= 30) || (hour === 13 && minute <= 30))) {
                        return true;
                    }
                }

                return false;

            },
            getAllInstruments() {
                InstrumentLogic
                    .getAll(Helpers.initPagination(20, 1, 'created_at', true))
                    .then(({data}) => {
                        this.instrumentsAvailable = data;
                        this.instrument = data[0];
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },

            setIntrument(instrument) {
                this.instrumentLoading = true;
                let body = {};

                if (instrument === 'all') {
                    body = {
                        instruments: this.instrumentsAvailable.map((instrument) => instrument.id)
                    };
                } else {
                    body = {
                        instrument,
                    };
                }

                return new Promise((resolve, reject) => {
                    UserLogic
                        .updateMe(body)
                        .then(() => {
                            AuthLogic.loadMe()
                                .then(() => {
                                    this.openInstrumentsDialog = false;
                                    resolve();
                                })
                                .catch((err) => reject(err))
                        })
                        .catch((err) => reject(err))
                        .finally(() => {
                            this.instrumentLoading = false;
                        })
                })
            },
            getWebNotifications() {
                WebNotificationLogic.get(
                    {
                        include: "",
                        read: true
                    }
                ).then(({data}) => {
                    this.notificationsLength = data.length;
                });
            },
        }
    }
</script>
