import { render, staticRenderFns } from "./CourseItem.vue?vue&type=template&id=2747bd93&scoped=true&"
import script from "./CourseItem.vue?vue&type=script&lang=js&"
export * from "./CourseItem.vue?vue&type=script&lang=js&"
import style1 from "./CourseItem.vue?vue&type=style&index=1&id=2747bd93&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2747bd93",
  null
  
)

export default component.exports