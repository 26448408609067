<template>
  <v-footer class="mt-4">
    <v-container>
      <v-row>

        <v-col cols="12" md="4">
          <h4>Communauté</h4>
          <v-list dense nav color="transparent">
            <v-list-item link :to="{name: 'member-video'}" exact>
              <v-list-item-content>Vidéos des {{this.$trans.choice('brand.customer_name', 2)}}</v-list-item-content>
            </v-list-item>
            <v-list-item link href="https://www.youtube.com/@MyGuitare/streams" target="_blank" exact>
              <div>Live Hebdo <v-badge v-if="showLiveBadge()" dot color="success" class="mb-1 ml-1"></v-badge></div>
            </v-list-item>
            <v-list-item link :to="{name: 'map'}" exact>
              <v-list-item-content>Carte des MyGuitaristes</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="4">
          <h4>Support</h4>
          <v-list dense nav color="transparent">
            <v-list-item link :to="{name: 'support'}" exact>
              <v-list-item-content>Aide</v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name: 'support-contact'}" exact>
              <v-list-item-content>Contact</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="4">
          <h4>Ressources</h4>
          <v-list dense nav color="transparent">
            <v-list-item link :to="{name: 'support-category', params: {category: 'ressources-telechargeables'}}" exact>
              <v-list-item-content>Téléchargements</v-list-item-content>
            </v-list-item>
            <v-list-item link :href="this.$platformStore.notMyPianoPop ? 'https://www.myguitare.com/blog/' : 'https://www.mypianopop.com/blog/'" target="_blank">
              <v-list-item-content>Blog</v-list-item-content>
            </v-list-item>
            <v-list-item link :href="this.$platformStore.notMyPianoPop ? 'https://www.youtube.com/@MyGuitare' : 'https://www.youtube.com/@MyPianoPop'" target="_blank">
              <v-list-item-content>Chaine Youtube</v-list-item-content>
            </v-list-item>
            <v-list-item link :href="this.theOtherPlatform.url" target="_blank">
              <v-list-item-content>{{ this.theOtherPlatform.name }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    theOtherPlatform: {
        name: process.env.VUE_APP_PLATFORM_CURRENT === process.env.VUE_APP_PLATFORM_MG ? 'MyPianoPop' : 'MyGuitare',
        url: process.env.VUE_APP_THE_OTHER_PLATFORM_URL,
    },

    }),
  methods: {
    showLiveBadge() {
        let now = new Date();
        let gmtPlusTwo = new Date(now.toLocaleString('en-FR', {timeZone: 'Europe/Paris'}));

        let day = gmtPlusTwo.getDay();
        let hour = gmtPlusTwo.getHours();
        let minute = gmtPlusTwo.getMinutes();

        if(day === 4 && ((hour === 12 && minute >= 30) || (hour === 13 && minute <= 30))) {
            return true;
        }

        return false;
    }
  }
}
</script>

<style scoped>
.white-footer {
  background-color: white !important;
}
</style>
